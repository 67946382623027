<template>
  <b-card>
    <b-modal
      modal-class="oderDetail"
      v-model="dialog"
      size="lg"
      title="Cập Nhật Thông Tin Khách Hàng"
      ok-title="Cập Nhật"
      cancel-title="Đóng"
      header-close-content=""
      @ok="addSale"
    >
      <b-container>
        <b-form>
          <div class="form-group row">
            <label for="username" class="col-md-3">Số Điện Thoại</label>
            <div class="col-md-9">
              <p>
                <strong>{{ editedItem.username }}</strong>
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label for="username" class="col-md-3">Tên Tài Khoản</label>
            <div class="col-md-9">
              <p>
                <strong>{{ editedItem.name }}</strong>
              </p>
            </div>
          </div>
          <div class="form-group row">
            <label for="username" class="col-md-3">Giới Thiệu Bởi</label>
            <div class="col-md-9">
              <p>
                <strong>{{ editedItem.root_inviter_code }}</strong>
              </p>
            </div>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-row class="table-filter">
      <!-- filter date from -->
      <b-col cols="2">
        <b-form-group id="input-from-date" label="Từ Ngày" label-for="from-date">
          <datePicker v-model="filter_from" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <!-- filter date to -->
      <b-col cols="2">
        <b-form-group id="input-to-date" label="Đến Ngày" label-for="to-date">
          <datePicker v-model="filter_to" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <!-- <b-col cols="2">
        <b-form-group id="input-root-filter" label="Giới Thiệu Bởi" label-for="root-filter">
          <b-form-input
            id="root-filter"
            v-model="root_inviter_code"
            type="text"
            placeholder="Giới Thiệu Bởi ..."
          ></b-form-input>
        </b-form-group>
      </b-col> -->
      <!-- Filter Status -->
      <!-- <b-col cols="2">
        <b-form-group id="input-status-filter" label="Trạng thái" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusOrderList">
            <template v-slot:first>
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col> -->
      <b-col cols="2">
        <b-button block variant="success" class="search-btn" @click="handleSearch">TÌM KIẾM</b-button>
      </b-col>

      <b-col cols="2">
        <b-button block variant="primary" class="search-btn" @click="handleExport">XUẤT EXCEL</b-button>
      </b-col>
    </b-row>
    <p>
      Hiển thị {{ (currentPage - 1) * perPage + 1 }}-{{
        currentPage * perPage > rows ? rows : currentPage * perPage
      }}
      trong {{ rows }}.
    </p>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      small
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(created_at)="item">{{ formatDate(item.value, 'YYYY-MM-DD HH:mm:ss') }}</template>
      <template v-slot:cell(date_of_birth)="item">{{ item.value ? formatDate(item.value, 'YYYY') : '' }}</template>
      <template v-slot:cell(status)="item" :v-if="item.value != 2">
        <b-badge class="mr-1 mb-1" v-if="item.value === 1" variant="success">Đã từng mua vé</b-badge>
        <b-badge class="mr-1 mb-1" v-else-if="item.value === null" variant="success">New</b-badge>
      </template>
      <template v-slot:cell(actions)="item" :v-if="item.value == 1">
        <b-button class="btn btn-icon btn-sm mx-3" variant="outline-secondary" @click="editItem(item.item)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </b-button>
      </template>
    </b-table>
    <b-row>
      <b-col cols="3">
        Hiển thị {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        trong {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Số dòng: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import ApiService from '@/core/services/api.service.js';
import Swal from 'sweetalert2';

// import axios from "axios";
export default {
  name: 'New-Customer',
  components: { datePicker },
  data() {
    return {
      isLoading: true,
      isFilterByGame: true,
      search: '',
      isBusy: false,
      statusOrderList: [
        { value: 1, text: 'Đã từng mua vé', variant: 'success' },
        { value: null, text: 'Chưa mua vé', variant: 'danger' }
      ],
      gameList: [],
      paymentMethodList: [],
      headers: [
        { label: 'Ngày Đăng Ký', key: 'created_at' },
        { label: 'Số Điện Thoại', key: 'username' },
        { label: 'Tên Tài Khoản', key: 'name' },
        { label: 'Năm Sinh', key: 'date_of_birth' },
        { label: 'Giới Thiệu Bởi', key: 'root_inviter_code' },
        { label: 'Trạng thái', key: 'status' },
        { label: 'Thao Tác', key: 'actions' }
      ],
      // Modal data
      items: [],
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      dialog: false,
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      timeout: null,
      filter_from: '',
      filter_to: '',
      username: '',
      invite_code: '',
      root_inviter_code: '',
      filter_status: null,
      editedItem: {},
      codeError: null
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Khách hàng mới' }]);
  },
  computed: {
    isAdmin() {
      return !this.$store.state.auth?.user?.data?.campaign || false;
    },
    partnerCampainId() {
      return this.$store.state.auth?.user?.data?.campaign_id;
    },
    campaignId() {
      return this.$store.state.auth.campaignId;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    },
    campaignId() {
      this.fetchData();
    }
  },
  methods: {
    handleExport() {
      this.exportData('/partners/exports/customers/new', this.getQueryParamsExport());
    },
    close() {
      this.editedItem = {};
    },
    validateInviteCode(code) {
      let regex = new RegExp(/^[a-zA-Z0-9]+$/);
      return code.trim().length && code.match(regex);
    },
    addSale(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.codeError = null;
      if (!this.validateInviteCode(this.editedItem.invite_code)) {
        this.codeError = 'Mã giới thiệu không hợp lệ';
        return;
      }
      ApiService.post('/partners/sales', {
        account_id: this.editedItem.id,
        invite_code: this.editedItem.invite_code
      })
        .then((response) => {
          if (!response || response.data.status || response.status == 204) {
            Swal.fire({
              title: '',
              text: 'Cập nhật thành công!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            });
            this.fetchData();
          } else {
            Swal.fire({
              title: '',
              text: 'Có lỗi xảy ra, vui lòng thử lại',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: '',
            text: 'Có lỗi xảy ra, vui lòng thử lại',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000
          });
        })
        .finally(() => {
          this.errorCode = null;
          this.dialog = false;
        });
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.dialog = true;
    },
    handleSearch() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(() => {
        this.fetchData();
      }, 400);
    },
    fetchData() {
      ApiService.query('/partners/customers/new', this.getQueryParams()).then((response) => {
        this.items = response.data.data.customerList;
        this.rows = response.data.data.total;
        this.isBusy = false;
      });
    },
    getQueryParams() {
      let to = '';
      if (this.filter_to) {
        to = new Date(this.filter_to);
        to.setDate(to.getDate() + 1);
      }
      let returnObj = {
        limit: this.perPage,
        offset: this.currentPage,
        from: this.filter_from ? new Date(this.filter_from) : '',
        to,
        invite_code: this.invite_code,
        root: this.root_inviter_code,
        status: this.filter_status == null ? -1 : this.filter_status
      };
      if (this.isAdmin) {
        returnObj.username = this.username || '';
      }
      return returnObj;
    },
    getQueryParamsExport() {
      let to = '';
      if (this.filter_to) {
        to = new Date(this.filter_to);
        to.setDate(to.getDate() + 1);
      }
      let returnObj = {
        from: this.filter_from ? new Date(this.filter_from) : '',
        to,
        invite_code: this.invite_code,
        root: this.root_inviter_code,
        status: this.filter_status == null ? -1 : this.filter_status
      };
      if (this.isAdmin) {
        returnObj.username = this.username || '';
      }
      return returnObj;
    }
  }
};
</script>
